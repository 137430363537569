<template>
 <div class="form" v-if="isActiveForm">
  <h3 style="color: white;  font-size: 30px; text-align: center">Send a request</h3>
  <input type="text" class="inp mt-3" placeholder="Name" v-model="dataForm.name">
  <input type="text" class="inp mt-3" placeholder="Company name" v-model="dataForm.nameCompany">
  <input type="email" class="inp mt-3" placeholder="Email" v-model="dataForm.email">
  <vue-tel-input wrapperClasses="rg" 
  inputClasses="rtb" class="rg mt-2" 
  ref="inp" 
  style="border: 1px solid #252525; height: 47px;outline: none; border-radius: 10px; box-shadow: none; background-color: #1E1E1E !important;"  
  v-model="phone" :style="{'height': '62px'}" 
  validCharactersOnly="true" 
  @validate="phoneObject" 
  @country-changed="setCountryCode"
  :enabledCountryCode="true"
  :enabledFlags="false"
  mode="international"> 
      <template v-slot:arrow-icon>
      </template>
  </vue-tel-input> 
  <div style="text-align: center" class="mt-3"><button class="inline-btn pointer" :class="{'isActivePaneL': !isActiveBtn}" @click="sendData">Send</button></div>
 </div>
 <div class="form" v-if="isGettingRequest">
  <h3 style="color: white; font-size: 30px; text-align: center">Application Received </h3>
  <p style="width: auto; text-align: center" class="mt-2">Thank you for interest! We will contact you soon!</p>
  <div style="text-align: center" class="mt-3"><button class="inline-btn pointer" @click="setGreate">Great</button></div>

 </div>
 <cookie class="cookie d-none-mobile"/>
 <div :class="{'isActivePaneL': isActiveForm || isGettingRequest}">
      <div class="top-banner d-none">
        <header>
          <div class="wrapper flex">
            <div class="lang" v-if="isActiveLang">
              <img src="../assets/img/lang.svg">
              <p class="lang-p">{{choiceLang}}</p>
              <img src="../assets/img/chevron-down.svg" class="left-strel pointer" @click="setActiveLang">
            </div>
            <div class="lang" v-if="!isActiveLang">
              <img src="../assets/img/lang.svg">
              <div style=" display: flex">
                <p class="lang-p pointer" @click="setLang('EN')">EN</p>
                <p class="lang-p pointer" @click="setLang('ES')">ES</p>
                <p class="lang-p pointer" @click="setLang('PT')">PT</p>
                <p class="lang-p pointer" @click="setLang('FR')">FR</p>
                <p class="lang-p pointer" @click="setLang('ZH')">ZH</p>
                <p class="lang-p pointer" @click="setLang('RU')">RU</p>
                <p class="lang-p pointer" @click="setLang('HE')">HE</p>
                <p class="lang-p pointer" @click="setLang('AR')">AR</p>
              </div>
              <img src="../assets/img/chevron-down.svg" class="top-strel pointer" @click="setActiveLang">
            </div>
            <nav class="flex-header-btn">
              <button class="border-btn pointer" @click="loginRediresct">{{lang[choiceLang]['Log in']}}</button>
              <button class="inline-btn ml-2 pointer" @click="signUpRediresct">{{lang[choiceLang]['Sign in']}}</button>
            </nav>
          </div>
        </header>
        <div class="introducing">
  
          <p class="intro-p">{{lang[choiceLang]['INTRODUCING']}}</p>
          <h1>MERCI<span class="orange-color h1">Q</span></h1>
  
          <p class="desc mt-4">{{lang[choiceLang]['logo desc']}}</p>
          
          <div class="flex flex-strel mt-4">
            <input type="text" class="username-input" :placeholder="lang[choiceLang]['username']" v-model="username">
            <div class="form-strel pointer" @click="findEmployee">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z" fill="#ABABAB"/>
              </svg>
            </div>
          </div> 
          <p class="error-input-text err-au" v-if="isError" style="color: #EA4335; text-align: right">{{lang[choiceLang]['error']}}</p> 
          <p class="desc mt-2">{{lang[choiceLang]['or']}}</p>
  
          <a href="https://merciq.app/client/scan"><div class="flex flex-strel scan-flex mt-2 pointer">
            <div class="scan-qr">
              <div class="ml-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16 17V16H13V13H16V15H18V17H17V19H15V21H13V18H15V17H16ZM21 21H17V19H19V17H21V21ZM3 3H11V11H3V3ZM5 5V9H9V5H5ZM13 3H21V11H13V3ZM15 5V9H19V5H15ZM3 13H11V21H3V13ZM5 15V19H9V15H5ZM18 13H21V15H18V13ZM6 6H8V8H6V6ZM6 16H8V18H6V16ZM16 6H18V8H16V6Z" fill="white"/>
                </svg>
              </div>
              <p class="ml-1">{{lang[choiceLang]['scan']}}</p>
            </div>
            <span class="flare"></span>
            <div class="form-strel pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z" fill="#ABABAB"/>
              </svg>
            </div>
          </div></a>
  
  
        </div>
        <div class="strel-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18.0001 28.7461L8.68945 19.4355L10.8108 17.3141L18.0001 24.5035L25.1895 17.3141L27.3108 19.4355L18.0001 28.7461ZM18.0001 20.2713L8.68945 10.9607L10.8108 8.8392L18.0001 16.0286L25.1895 8.8392L27.3108 10.9607L18.0001 20.2713Z" fill="white"/>
          </svg>
        </div>
      </div>
  
      <div class="wrapper-sccens flex" >
        <div class="div-screens" ref="screen-animation" >
          <img src="../assets/img/screen-1.png">
          <img src="../assets/img/screen-2.png" >
        </div>
        <div class="screen-text" ref="text-animation" v-html="lang[choiceLang]['2-desc']">
          
          
        </div>
      </div>
      
  
      <div class="wrapper get-tips">
        <h2 @click="setActiveForms">{{lang[choiceLang]['3-title']}}</h2>
        <p class="mt-3 mt-2-mobile" >{{lang[choiceLang]['3-desc']}}</p>
        <button class="border-btn mt-4" style="position: relative; z-index: 7" @click="setActiveForms">{{lang[choiceLang]['3-btn']}}</button>
       
        <div class="get-tips-img" style="position: relative; z-index: 2">
          <img src="../assets/img/company.png">
        </div>
      </div>
     
      <div class="wrapper analize-div">
        <h2>{{lang[choiceLang]['4-title']}}</h2>
        <p class="mt-3 mt-2-mobile">{{lang[choiceLang]['4-desc']}}</p>
        <img src="../assets/img/screen-3.png" class="mt-6 mt-5-mobile">
      </div>
  
      <div class="wrapper" >
        <div class="wrapper-1-circle">
          <div class="wrapper-2-circle">
            <div class="wrapper-3-circle">
              <p class="pr-0">0%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="wrapper-1-1-circle" style="position: absolute;">
          <div class="wrapper-2-circle wrapper-dop-circle">
            <div class="wrapper-3-circle wrapper-dop-circle">
            </div>
          </div>
        </div>
      </div>
  
      <div class="wrapper fee-info mt-6 mt-5-mobile">
        <h2>{{lang[choiceLang]['5-title']}}</h2>
        <p class="mt-3 mt-2-mobile" v-html="lang[choiceLang]['5-desc']"></p>
      </div>
      <div class="wrapper footer-wrapper">
        <div class="footer"> 
          <h2>{{lang[choiceLang]['6-title']}}</h2>
          <p class="mt-3">{{lang[choiceLang]['6-desc']}}</p>
          <a href="https://merciq.app/demo/workspace"><button class="inline-btn pointer">{{lang[choiceLang]['6-btn']}}</button></a>
        </div>
      </div>
  
      <footer>
        <div class="wrapper">
          <div class="address">
            <a href="/" style="text-decoration: none"><h4>MERCI<span class="orange-color h4">Q</span></h4></a>
            <p class="mt-2">MerciQ Solutions LLC<br>7901 4th St. N<br>#17109<br>St. Petersburg, FL 33702</p>
          </div>
          <div class="links">
            <div><a href="https://merciq.app/docs/Terms%20and%20Conditions.pdf">Terms & Conditions</a></div>
            <div><a href="https://merciq.app/docs/Privacy%20Policy.pdf">Privacy Policy</a></div>
            <div><a href="https://merciq.app/docs/Fee%20Plan.pdf">Fee Plan</a></div>
            <div><a href="/Cookie%20policy.pdf">Cookie Policy</a></div>
            <div><a href="/cookie">Cookie declaration</a></div>
            <div><a href="/faq">FAQ</a></div>
            <div><a href="/contact">Contact Us</a></div>
            <!-- <div><a href="/blog">Blog & Updates</a></div> -->
          </div>
        </div>
      </footer>
  
    </div>
  
  </template>
  
  <script>
  import langs from '../mixins/langs.js'
  

  export default {
    name: 'App',
    mixins: [langs],
    data() {
      return {
        username: '',
        show: false,
        isError: false,
        isActiveLang: true,
        choiceLang: 'EN',
        isActiveForm: false,
        phone: '',
        countryCode: '',
        isValidPhone: false,
        isActiveBtn: false,
        dataForm: {
          name: '',
          nameCompany: '',
          email: ''
        },
        isGettingRequest: false
      }
    },
    watch: {
      username() {
          if(this.username[0] != "@") this.username = '@' + this.username
      },
      phone() {
        if(this.phone.length < this.countryCode.length) {
          this.phone = this.countryCode
        }
        this.isIsset()
      },
      isActiveBtn() {
        console.log(this.isActiveBtn)
      },
      countryCode() {
        this.phone = this.countryCode
      },
      'dataForm.name': function() {
        this.isIsset()
      },
      'dataForm.nameCompany': function() {
        this.isIsset()
      },
      'dataForm.email': function() {
        this.isIsset()
      }
    },
    methods: {
      isIsset() {
        const validateEmail = (email) => {
          return String(email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };
        if(validateEmail(this.dataForm.email) && 
        this.dataForm.name.trim() != '' &&
        this.dataForm.nameCompany.trim() != '' &&
        this.isValidPhone) {
          this.isActiveBtn = true
        } else {
          this.isActiveBtn = false
        }
      },
      phoneObject(val) {
        this.isValidPhone = val.valid
        this.isIsset()
      },
      setCountryCode(val) {
        this.countryCode = "+" + val.dialCode
      },
      setGreate() {
        this.isGettingRequest = !this.isGettingRequest
      },
      setActiveForms() {
          this.isActiveForm = !this.isActiveForm
      },
      sendData() {
        if(this.isActiveBtn) {
          this.setActiveForms()
          this.setGreate()
        }
      },
      setActiveLang() {
        this.isActiveLang = !this.isActiveLang
      },
      setLang(lang) {
        this.choiceLang = lang
        localStorage.setItem('lang', this.choiceLang)
        this.isActiveLang = !this.isActiveLang
      },
      signUpRediresct() {
        window.location.href = 'https://merciq.app/signupshort'
      },
      loginRediresct() {
        window.location.href = 'https://merciq.app/staff/signin'
      },
      getDataEmploy(object) {
      object = object.split('@').join("")
      let response = async() => {
          let query = await fetch('https://merciq.app/api/user/get/client/' + object, {
              method: "GET"
          })
          console.log(query)
          if(query.status != 200) return Promise.resolve({error: 'Employee not found'})
          let result = await query.json()
  
          return Promise.resolve(result)
      }
  
      return response()
      },
      findEmployee() {
          this.getDataEmploy(this.username.split("@").join("")).then(data => {
           
              if(data?.error != undefined) this.isError = true
              else window.location.href = 'https://merciq.app/client/employee/' + this.username.split("@").join("")
          })
      }
    },
    mounted() {
      function start() {
        
  
    let element = document.querySelector('.div-screens');
  
  let Visible =  (target) => {
 
      // Все позиции элемента
      let targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight
        };
      if (targetPosition.bottom > windowPosition.top && 
        targetPosition.top < windowPosition.bottom && 
        targetPosition.right > windowPosition.left && 
        targetPosition.left < windowPosition.right) { 

        document.querySelector('.screen-text').classList.add('screen-text-animation')
        document.querySelector('.div-screens').classList.add('div-screens-animation')
        document?.querySelector('.cookie')?.classList?.remove('d-none-mobile')
      } 
    
  };
  let element_1 = document.querySelector('.wrapper-1-circle');
  let element_2 = document.querySelector('.pr-0');
  let pr = 100
  let aut = 0
let NewVisible =  (target) => {

      // Все позиции элемента
      let targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight
        };
      if (targetPosition.bottom > windowPosition.top && 
        targetPosition.top < windowPosition.bottom && 
        targetPosition.right > windowPosition.left && 
        targetPosition.left < windowPosition.right) { 
        if(pr == 100  && aut == 0) {
          aut = 1
          let timer = setInterval(() => {
            document.querySelector('.pr-0').innerHTML = pr + '%'
            if(pr <= 0) clearInterval(timer)
            pr -= 1
          }, 40);
          document.querySelector('.wrapper-1-1-circle').classList.add('wrapper-1-1-circle-animation')
        }
      } 
    
  };
window.addEventListener('scroll', function() {
  Visible (element);
  if(pr == 100 ) {

    NewVisible(element_1)
  }
});

Visible (element);

      }
      start()
    },
    beforeMount() {
      
      if(this.$route?.params?.lang == undefined) {

        if(localStorage.getItem('lang') == undefined) {
          this.choiceLang = "EN"
          localStorage.setItem('lang', 'EN')
        } else {
          this.choiceLang = localStorage.getItem('lang')
        }
      } else {
        localStorage.setItem('lang', this.$route.params.lang.toUpperCase())
        this.choiceLang = this.$route.params.lang.toUpperCase()
      }
      
     
    }
  }
  </script>
  
  