<template>
    <cookie/>
    <div class="FAQ-banner">
        <header>
          <div class="wrapper flex">
            <div class="lang" v-if="isActiveLang">
              <img src="../assets/img/lang.svg">
              <p class="lang-p">{{choiceLang}}</p>
              <img src="../assets/img/chevron-down.svg" class="left-strel pointer" @click="setActiveLang">
            </div>
            <div class="lang" v-if="!isActiveLang">
              <img src="../assets/img/lang.svg">
              <div style=" display: flex">
                <p class="lang-p pointer" @click="setLang('EN')">EN</p>
                <p class="lang-p pointer" @click="setLang('ES')">ES</p>
                <p class="lang-p pointer" @click="setLang('PT')">PT</p>
                <p class="lang-p pointer" @click="setLang('FR')">FR</p>
                <p class="lang-p pointer" @click="setLang('ZH')">ZH</p>
                <p class="lang-p pointer" @click="setLang('RU')">RU</p>
                <p class="lang-p pointer" @click="setLang('HE')">HE</p>
                <p class="lang-p pointer" @click="setLang('AR')">AR</p>
              </div>
              <img src="../assets/img/chevron-down.svg" class="top-strel pointer" @click="setActiveLang">
            </div>
            <nav class="flex-header-btn">
              <button class="border-btn pointer" @click="loginRediresct">{{lang[choiceLang]['Log in']}}</button>
              <button class="inline-btn ml-2 pointer" @click="signUpRediresct">{{lang[choiceLang]['Sign in']}}</button>
            </nav>
          </div>
        </header>
    </div>
    <div class="wrapper">
        <a href="/"><h2 class="FAQ-title mt-2 pointer" @click="$router.push('/')" style="font-size: 16px; color: rgba(242, 142, 34, 1); display: flex; align-items: center;margin-left: 0"><img src="../assets/img/chevron-down.svg" style="transform: rotate(90deg)">To Home Page</h2></a>
        <h2 class="FAQ-title mt-2">Contact us</h2>
    </div>
    <div class="container contact-div">
        <div class="wrapper faq-wrapper">
            <div class="contact-div">
                <div><a class="contact-div-text" href="mailto:support@merciq.com">Our mail: <span class="orange-color">support@merciq.com</span></a></div>
                <div class="mt-1"><a class="contact-div-text" href="https://api.whatsapp.com/send/?phone=%2B393314657539&text&type=phone_number&app_absent=0">Our phone: <span class="orange-color">+39 331 465 7539</span></a></div>
                <div><p class="contact-div-text center mt-2" style="width: auto">Contact us, we will definitely help you!</p></div>
            </div>
        </div>
    </div>
    <footer class="mt-5" style="margin-top: 100px">
        <div class="wrapper">
          <div class="address">
            <a href="/" style="text-decoration: none"><h4>MERCI<span class="orange-color h4">Q</span></h4></a>
            <p class="mt-2">MerciQ Solutions LLC<br>7901 4th St. N<br>#17109<br>St. Petersburg, FL 33702</p>
          </div>
          <div class="links">
            <div><a href="https://merciq.app/docs/Terms%20and%20Conditions.pdf">Terms & Conditions</a></div>
            <div><a href="https://merciq.app/docs/Privacy%20Policy.pdf">Privacy Policy</a></div>
            <div><a href="https://merciq.app/docs/Fee%20Plan.pdf">Fee Plan</a></div>
            <div><a href="/Cookie%20policy.pdf">Cookie Policy</a></div>
            <div><a href="/cookie">Cookie declaration</a></div>
            <div><a href="/faq">FAQ</a></div>
            <div><a href="/contact">Contact Us</a></div>
            <!-- <div><a href="/blog">Blog & Updates</a></div> -->
          </div>
        </div>
      </footer>
</template>

<script>
  import langs from '../mixins/langs.js'

    export default {
        mixins: [langs],
        data() {
            return {
                username: '',
                show: false,
                isError: false,
                isActiveLang: true,
                choiceLang: 'EN',
                isActive: [false, false, false, false, false]
            }
        },
        methods: {
            setActiveLang() {
                this.isActiveLang = !this.isActiveLang
            },
            setLang(lang) {
                this.choiceLang = lang
                localStorage.setItem('lang', this.choiceLang)
                this.isActiveLang = !this.isActiveLang
            },
            setActive(index) {
                this.isActive[index] = !this.isActive[index]
            },
            signUpRediresct() {
                window.location.href = 'https://merciq.app/signupshort'
            },
            loginRediresct() {
                window.location.href = 'https://merciq.app/staff/signin'
            },
        },
        beforeMount() {
            if(localStorage.getItem('lang') == undefined) {
                this.choiceLang = "EN"
                localStorage.setItem('lang', 'EN')
            } else {
                this.choiceLang = localStorage.getItem('lang')
            }
        }
    }
</script>