<template>
    <div class="cookie" v-if="isActive" style="z-index: 20;">
        <div class="wrapper flex">
            <p class="cookie-title">This website uses cookies</p>
            <p>We use cookies to personalise content, find out more details in our <a href="/Cookie%20policy.pdf">Cookie Policy</a>.</p>
            <button class="inline-btn pointer" @click="setCookie">Accept</button>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isActive: true
            }
        },
        methods: {
            setCookie() {
                const cyrb53 = (str, seed = 0) => {
                    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
                    for(let i = 0, ch; i < str.length; i++) {
                        ch = str.charCodeAt(i);
                        h1 = Math.imul(h1 ^ ch, 2654435761);
                        h2 = Math.imul(h2 ^ ch, 1597334677);
                    }
                    h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
                    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
                    h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
                    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
                
                    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
                };
                function makeid(length) {
                        let result = '';
                        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                        const charactersLength = characters.length;
                        let counter = 0;
                        while (counter < length) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                        counter += 1;
                        }
                        return result;
                    }
                const str = makeid(5)
                localStorage.setItem('cookie_id', cyrb53(str))
                localStorage.setItem('date', new Date())
                localStorage.setItem('get_cookie', new Date())
                this.isActive = false
            }
        },
        beforeMount() {
            
            if(localStorage.getItem('cookie_id') != undefined) {
                this.isActive = false
            }
        }
    }
</script>