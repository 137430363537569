<template>
    
    <div style="position: absolute;
    top: 50%;
    transform: translateY(-50%); text-align: center; left: 0; right: 0">
     
        <h1 style="color: white; font-size: 70px">MERCI<span class="orange-color h1">Q</span></h1>
        <h1 style="color: rgba(242, 142, 34, 1)">404</h1>
        <h3 style="color: white; font-family: 'Rubik-Light'; font-size: 30px">Not Found</h3>
        <a href="/"><p class="mt-3" style="color: rgba(242, 142, 34, 1); width: auto">to Home Page</p></a>
    </div>
</template>

<script>
    export default {
        name: 'not found'
    }
</script>