export default {
    data() {
        return {
            lang: {
                'EN': {
                  'Log in': 'Log in',
                  'Sign in': 'Sign in',
                  'INTRODUCING': 'INTRODUCING',
                  'logo desc': `To send a tip, enter the receiver's username or scan their QR code.`,
                  'username': '@username',
                  'or': 'Or',
                  'error': 'Employee not found',
                  'scan': 'Upload a QR code',
                  '2-desc': 'The easiest way to say <span class="orange-color">«thanks»</span> or get your tips',
                  '3-title': 'SEND & GET TIPS IN 10 SECONDS!',
                  '3-desc': `We support all major types of online payment methods - from
        credit card to Apple / Google Pay`,
                  '3-btn': 'Get MerciQ',
                  '4-title': 'EARN TIPS & ANALYZE YOUR SUCCESS!',
                  '4-desc': 'Built in dashboard with analytics highlighting your earnings and customer feedback.',
                  '5-title': 'ESCAPE FROM FEES AND TAX ROUTINE',
                  '5-desc': `We offer a <span class="orange-color">FREE</span> service for business with 0% fee to simplify the process of receiving tips and
        interact with the client in one touch!`,
                  '6-title': 'GET MORE FEEDBACK!',
                  '6-desc': `Receive feedback from customers with an option to integrate it your website via API.`,
                  '6-btn': 'GO TO DEMO'
                },
                'ES': {
                  'Log in': 'Iniciar sesión',
                  'Sign in': 'Registrarse',
                  'INTRODUCING': 'PRESENTAMOS',
                  'logo desc': `Para enviar una propina, ingresa el nombre del receptor o escanea su código QR.`,
                  'username': '@nombre_de_usuario',
                  'or': 'o',
                  'error': 'Empleado no encontrado',
                  'scan': 'Escanear código QR',
                  '2-desc': 'La forma más sencilla de decir <span class="orange-color">"gracias"</span> o recibir tus propinas',
                  '3-title': '¡ENVÍA Y RECIBE PROPINAS EN 10 SEGUNDOS!',
                  '3-desc': `Admitimos todos los tipos principales de métodos de pago en línea, desde tarjeta de crédito hasta Apple/Google Pay.`,
                  '3-btn': 'Únete a MerciQ',
                  '4-title': '¡GANA PROPINAS Y ANALIZA TU ÉXITO!',
                  '4-desc': 'Panel integrado con analítica, mostrando tu progreso, ganancias y reseñas de clientes.',
                  '5-title': '¡EVITA COMISIONES Y RUTINAS!',
                  '5-desc': `Ofrecemos un servicio <span class="orange-color">GRATUITO</span> para negocios con 0% de comisión, para simplificar el proceso de
        recibir propinas e interactuar con el cliente con un solo toque!`,
                  '6-title': '¡OBTÉN MÁS OPINIONES!',
                  '6-desc': `Otra manera de recibir opiniones de los clientes con la posibilidad de integración en tu sitio web a
        través de API`,
                  '6-btn': 'IR A LA DEMO'
                },
                'PT': {
                  'Log in': 'Entrar',
                  'Sign in': 'Registrar',
                  'INTRODUCING': 'APRESENTAMOS',
                  'logo desc': `Para enviar uma gorjeta, insira o nome do destinatário ou escaneie o seu código QR`,
                  'username': '@nome_do_usuário',
                  'or': 'ou',
                  'scan': 'Escanear código QR',
                  'error': 'Funcionário não encontrado',
                  '2-desc': 'A maneira mais fácil de dizer <span class="orange-color">"obrigado"</span> ou receber suas gorjetas',
                  '3-title': 'ENVIE E RECEBA GORJETAS EM 10 SEGUNDOS!',
                  '3-desc': `Oferecemos suporte a todos os principais tipos de métodos de pagamento on-line - desde cartão de crédito até Apple/Google Pay`,
                  '3-btn': 'Junte-se ao MerciQ',
                  '4-title': 'GANHE GORJETAS E ANALISE SEU SUCESSO!',
                  '4-desc': 'Painel embutido com análise, mostrando seu progresso, ganhos e feedback dos clientes',
                  '5-title': 'EVITE COMISSÕES E ROTINAS',
                  '5-desc': `Oferecemos um serviço <span class="orange-color">GRATUITO</span>  para negócios com 0% de comissão, para simplificar o processo
        de receber gorjetas e interagir com o cliente com um toque!`,
                  '6-title': 'RECEBA MAIS FEEDBACK!',
                  '6-desc': `Outra maneira de receber feedback dos clientes com a possibilidade de integração no seu site via API`,
                  '6-btn': 'IR PARA A DEMO'
                },
                'FR': {
                    'Log in': 'Se connecter',
                    'Sign in': `S'inscrire`,
                    'INTRODUCING': 'PRÉSENTATION',
                    'logo desc': `Pour envoyer un pourboire, saisissez le nom d'utilisateur du destinataire ou scannez son code QR.`,
                    'username': `@nom_d'utilisateur`,
                    'or': 'ou',
                    'error': 'Employé introuvable',
                    'scan': 'Scanner le code QR',
                    '2-desc': 'La manière la plus simple de dire <span class="orange-color">«merci»</span>  ou de recevoir vos pourboires.',
                    '3-title': 'ENVOYEZ ET RECEVEZ UN POURBOIRE EN 10 SECONDES !',
                    '3-desc': `Nous prenons en charge tous les principaux types de méthodes de paiement en ligne - de la carte de crédit à Apple/Google Pay`,
                    '3-btn': 'Rejoindre MerciQ',
                    '4-title': 'GAGNEZ DES POURBOIRES ET ANALYSEZ VOTRE SUCCÈS',
                    '4-desc': 'Tableau de bord intégré avec des analyses mettant en évidence vos gains et les retours des clients.',
                    '5-title': 'ÉCHAPPEZ AUX FRAIS ET À LA ROUTINE',
                    '5-desc': `Nous proposons un service <span class="orange-color">GRATUIT</span> pour les entreprises avec 0% de frais pour simplifier le
                    processus de réception des pourboires et interagir avec le client en un seul clic !`,
                    '6-title': `OBTENEZ PLUS D'AVIS !`,
                    '6-desc': `Une autre façon de recevoir des avis de clients avec la possibilité de l'intégrer à votre site web via API`,
                    '6-btn': 'ALLER À LA DÉMO'
                  },
                  'ZH': {
                    'Log in': '登录',
                    'Sign in': ` 注册`,
                    'INTRODUCING': '介绍',
                    'logo desc': `要发送⼩费，请输⼊接收者的⽤户名或扫描他们的QR码`,
                    'username': `@⽤户名`,
                    'or': '或者',
                    'error': '未找到员工',
                    'scan': '扫描QR码',
                    '2-desc': '说<span class="orange-color">“谢谢”</span>或获取您的⼩费的最简单⽅式',
                    '3-title': '在10秒内发送并获取⼩费！',
                    '3-desc': `我们支持所有主要类型的在线支付方式 - 从信用卡到 Apple / Google Pay`,
                    '3-btn': '加⼊MerciQ',
                    '4-title': ' 赚取⼩费并分析您的成功',
                    '4-desc': '内置的分析⾯板，显示您的进度，收⼊和客户反馈',
                    '5-title': '避免佣⾦和例⾏公事',
                    '5-desc': `我们为企业提供免费服务，佣⾦为0%，以简化接收⼩费和⼀键与客户交互的过程`,
                    '6-title': `获得更多反馈！`,
                    '6-desc': `通过API将其集成到您的⽹站上，从客户那⾥获得更多反馈的另⼀种⽅式`,
                    '6-btn': '前往演示'
                  },
                  'RU': {
                    'Log in': 'Войти',
                    'Sign in': `Регистрация`,
                    'INTRODUCING': 'ПРЕДСТАВЛЯЕМ',
                    'logo desc': `Чтобы отправить чаевые, введите имя получателя или отсĸанируйте его QR-ĸод`,
                    'username': `@Имя_пользователя`,
                    'or': 'или',
                    'scan': 'Отсканировать qr-код',
                    'error': 'Сотрудник не найден',
                    '2-desc': 'Самый простой способ сказать <span class="orange-color">«спасибо»</span> или получить чаевые',
                    '3-title': 'ОТПРАВЬТЕ & ПОЛУЧИТЕ ЧАЕВЫЕ ЗА 10 СЕКУНД!',
                    '3-desc': `Мы поддерживаем все основные способы онлайн-платежей — от кредитной карты до Apple/Google Pay.`,
                    '3-btn': 'Получить MerciQ',
                    '4-title': 'ЗАРАБАТЫВАЙТЕ ЧАЕВЫЕ & АНАЛИЗИРУЙТЕ СВОЙ УСПЕХ!',
                    '4-desc': 'Встроенная панель мониторинга с аналитикой, отображающей ваши доходы и отзывы клиентов.',
                    '5-title': 'БЕГСТВО ОТ СБОРОВ И НАЛОГОВОЙ РУТИНЫ',
                    '5-desc': `Мы предлагаем <span class="orange-color">БЕСПЛАТНЫЙ</span> сервис для бизнеса с комиссией 0%, чтобы упростить процесс получения чаевых и взаимодействия с клиентом в одно касание!`,
                    '6-title': `ПОЛУЧИТЕ БОЛЬШЕ ОБРАТНОЙ СВЯЗИ!`,
                    '6-desc': `Еще один канал получения обратной связи от клиентов с возможностью интеграции на ваш сайт через API`,
                    '6-btn': 'ПЕРЕЙТИ К ДЕМО'
                  },
                  'AR':  {
                    'Log in': 'ليأتي',
                    'Sign in': `تسجيل`,
                    'INTRODUCING': 'مقدمة',
                    'logo desc': `أدخل اسم المستخدم وابدأ البحث`,
                    'username': `@اسم المستخدم`,
                    'or': 'أو',
                    'scan': 'مسح رمز الاستجابة السريعة',
                    'error': 'لم يتم العثور على الموظف',
                    '2-desc': 'أسهل طريقة لقول <span class="orange-color">"شكرًا لك"</span> أو الحصول على نصيحة',
                    '3-title': 'إرسال واستقبال النصائح في 10 ثانية!',
                    '3-desc': `نحن ندعم جميع الأنواع الرئيسية لطرق الدفع عبر الإنترنت - بدءًا من بطاقة الائتمان وحتى Apple / Google Pay`,
                    '3-btn': 'احصل على ميرسي كيو',
                    '4-title': 'احصل على النصائح وقم بتحليل نجاحك!',
                    '4-desc': 'لوحة تحكم مدمجة تحتوي على تحليلات توضح إيراداتك وتعليقات العملاء.',
                    '5-title': 'الهروب من الرسوم والضرائب الروتينية',
                    '5-desc': `نحن نقدم خدمة <span class="orange-color">مجانية</span> للشركات بعمولة 0% لتبسيط عملية تلقي النصائح والتفاعل مع العميل بلمسة واحدة!`,
                    '6-title': `احصل على المزيد من التعليقات!`,
                    '6-desc': `قناة أخرى لتلقي تعليقات العملاء مع إمكانية التكامل مع موقع الويب الخاص بك عبر واجهة برمجة التطبيقات (API).`,
                    '6-btn': 'انتقل إلى العرض التوضيحي'
                  },
                  'HE':  {
                    'Log in': 'להיכנס',
                    'Sign in': `הַרשָׁמָה`,
                    'INTRODUCING': 'היכרות',
                    'logo desc': `כדי לשלוח טיפ, הזינו את שם הנמען או סרוק את קוד ה-QR שלו`,
                    'username': `@שם משתמש`,
                    'or': 'אוֹ',
                    'scan': 'סרוק קוד QR',
                    'error': 'עובד לא נמצא',
                    '2-desc': 'הדרך הקלה ביותר לומר <span class="orange-color">"תודה"</span> או לקבל טיפ',
                    '3-title': 'שלח וקבל טיפים תוך 10 שניות!',
                    '3-desc': `אנו תומכים בכל הסוגים העיקריים של אמצעי התשלום המקוונים - מכרטיס אשראי ועד Apple / Google Pay`,
                    '3-btn': 'קבל את MerciQ',
                    '4-title': 'קבל טיפים ונתח את ההצלחה שלך!',
                    '4-desc': 'לוח מחוונים מובנה עם ניתוח המציג את ההכנסות שלך ואת המשוב של הלקוחות.',
                    '5-title': 'בריחה מעמלות ושגרת מס',
                    '5-desc': `אנו מציעים שירות <span class="orange-color">בחינם</span> לעסקים עם 0% עמלה כדי לפשט את תהליך קבלת הטיפים והאינטראקציה עם הלקוח בנגיעה אחת!`,
                    '6-title': `קבל עוד משוב!`,
                    '6-desc': `ערוץ נוסף לקבלת משוב מלקוחות עם יכולת להשתלב באתר שלכם באמצעות API`,
                    '6-btn': 'עבור להדגמה'
                  },
              }
        }
    }
}