<template>
    <cookie/>
    <div class="FAQ-banner">
        <header>
          <div class="wrapper flex">
            <div class="lang" v-if="isActiveLang">
              <img src="../assets/img/lang.svg">
              <p class="lang-p">{{choiceLang}}</p>
              <img src="../assets/img/chevron-down.svg" class="left-strel pointer" @click="setActiveLang">
            </div>
            <div class="lang" v-if="!isActiveLang">
              <img src="../assets/img/lang.svg">
              <div style=" display: flex">
                <p class="lang-p pointer" @click="setLang('EN')">EN</p>
                <p class="lang-p pointer" @click="setLang('ES')">ES</p>
                <p class="lang-p pointer" @click="setLang('PT')">PT</p>
                <p class="lang-p pointer" @click="setLang('FR')">FR</p>
                <p class="lang-p pointer" @click="setLang('ZH')">ZH</p>
                <p class="lang-p pointer" @click="setLang('RU')">RU</p>
                <p class="lang-p pointer" @click="setLang('HE')">HE</p>
                <p class="lang-p pointer" @click="setLang('AR')">AR</p>
              </div>
              <img src="../assets/img/chevron-down.svg" class="top-strel pointer" @click="setActiveLang">
            </div>
            <nav class="flex-header-btn">
              <button class="border-btn pointer" @click="loginRediresct">{{lang[choiceLang]['Log in']}}</button>
              <button class="inline-btn ml-2 pointer" @click="signUpRediresct">{{lang[choiceLang]['Sign in']}}</button>
            </nav>
          </div>
        </header>
    </div>
    <div class="wrapper">
        <a href="/"><h2 class="FAQ-title mt-2 pointer" @click="$router.push('/')" style="font-size: 16px; color: rgba(242, 142, 34, 1); display: flex; align-items: center;margin-left: 0"><img src="../assets/img/chevron-down.svg" style="transform: rotate(90deg)">To Home Page</h2></a>
        <h2 class="FAQ-title mt-2">Blog & Updates</h2>
    </div>
    <div class="wrapper faq-wrapper">
        <div class="faq-el mt-2">
            <div class="flex">
                <h3>What is MerciQ?</h3>
                <img src="../assets/img/chevron-down.svg" 
                @click="setActive(0)" class="pointer" 
                :style="{'transform': (!isActive[0]) ? 'none' : 'rotate(180deg)'}">
            </div>
            <p v-if="isActive[0]">MerciQ is a cashless, online gratuity application that allows users to send and receive tips with a simple click.<br>We understand the important role that tips play for small businesses and self-employed people, so MerciQ is a completely free service for partners.<br>Simply log into your personal account to access detailed financial analytics and customer reviews — you can also use the app to quickly and accurately prepare financial statements for tax purposes.<br>MerciQ serves as the best assistant for waiters, bartenders, drivers, barbers, and all other service providers.</p>
        </div>
        <div class="faq-el mt-2">
            <div class="flex">
                <h3>Why is MerciQ Beneficial?</h3>
                <img src="../assets/img/chevron-down.svg"  @click="setActive(1)" class="pointer" 
                :style="{'transform': (!isActive[1]) ? 'none' : 'rotate(180deg)'}">
            </div>
            <p v-if="isActive[1]">American consumers are moving away from cash for all of their daily purchases — in fact, 90% primarily use their debit or credit cards for these transactions. And when it comes time for them to leave a tip, banks reap a commission on each one.<br>As a result of these commissions, businesses and employees incur recurring and significant financial losses. That is why MerciQ shifts the bank’s commission to your business's client, as the cost to them ends up being a one-time fee of a few extra cents.<br>Thus, with MerciQ, your business and your employees earn more!</p>
        </div>
        <div class="faq-el mt-2">
            <div class="flex">
                <h3>What is cashless tipping?</h3>
                <img src="../assets/img/chevron-down.svg"  @click="setActive(2)" class="pointer" 
                :style="{'transform': (!isActive[2]) ? 'none' : 'rotate(180deg)'}">
            </div>
            <p v-if="isActive[2]">Cashless tipping rewards good service with gratuity through digital means as opposed to cash. This can be done via payment apps, credit cards, or even cryptocurrencies — making it a convenient option in an increasingly cashless society. <br>It benefits both patrons and workers. Patrons are able to easily and securely distribute tips, even after leaving the premises. And workers receive this gratuity easily and promptly. <br>Moreover, cashless tipping — which is gaining traction in the restaurant industry — promotes the fair distribution of tips and increases transparency, reassuring customers that their money is going to the intended recipient.</p>
        </div>
        <div class="faq-el mt-2">
            <div class="flex">
                <h3>What can MerciQ be used for?</h3>
                <img src="../assets/img/chevron-down.svg"  @click="setActive(3)" class="pointer" 
                :style="{'transform': (!isActive[3]) ? 'none' : 'rotate(180deg)'}">
            </div>
            <p v-if="isActive[3]">Cashless tipping is a modern and convenient practice that can be incorporated across a variety of service sectors.<br>Whether it’s restaurants and cafes, spas and salons, or delivery services, this service provides a straightforward option for customers to express their appreciation for good service. Even small, local businesses and independent contractors could implement this system to simplify transactions and encourage a culture of appreciation.<br>No more tip jars with just spare change inside and no need to worry about whether the customer has enough cash to provide a tip.<br>As the shift towards cashless transactions accelerates, cashless tipping is a step forward in modernizing a business’s service quality, and MerciQ serves as the all-in-one platform for these transactions</p>
        </div>
        <div class="faq-el mt-2">
            <div class="flex">
                <h3>How to join to MerciQ?</h3>
                <img src="../assets/img/chevron-down.svg"  @click="setActive(4)" class="pointer" 
                :style="{'transform': (!isActive[4]) ? 'none' : 'rotate(180deg)'}">
            </div>
            <p v-if="isActive[4]">MerciQ is accepting applications to join now! The form will take about 5-10 minutes, and it asks for basic information about your business or service. Afterwards, we will activate your account and you will be all set to start using the app.</p>
        </div>
    </div>
    <footer class="mt-2">
        <div class="wrapper">
          <div class="address">
            <a href="/" style="text-decoration: none"><h4>MERCI<span class="orange-color h4">Q</span></h4></a>
            <p class="mt-2">MerciQ Solutions LLC<br>7901 4th St. N<br>#17109<br>St. Petersburg, FL 33702</p>
          </div>
          <div class="links">
            <div><a href="https://merciq.app/docs/Terms%20and%20Conditions.pdf">Terms & Conditions</a></div>
            <div><a href="https://merciq.app/docs/Privacy%20Policy.pdf">Privacy Policy</a></div>
            <div><a href="https://merciq.app/docs/Fee%20Plan.pdf">Fee Plan</a></div>
            <div><a href="/Cookie%20policy.pdf">Cookie Policy</a></div>
            <div><a href="/cookie">Cookie declaration</a></div>
            <div><a href="/faq">FAQ</a></div>
            <div><a href="/contact">Contact Us</a></div>
          </div>
        </div>
      </footer>
</template>

<script>
  import langs from '../mixins/langs.js'

    export default {
        mixins: [langs],
        data() {
            return {
                username: '',
                show: false,
                isError: false,
                isActiveLang: true,
                choiceLang: 'EN',
                isActive: [false, false, false, false, false]
            }
        },
        methods: {
            setActiveLang() {
                this.isActiveLang = !this.isActiveLang
            },
            setLang(lang) {
                this.choiceLang = lang
                localStorage.setItem('lang', this.choiceLang)
                this.isActiveLang = !this.isActiveLang
            },
            setActive(index) {
                this.isActive[index] = !this.isActive[index]
            },
            signUpRediresct() {
                window.location.href = 'https://merciq.app/signupshort'
            },
            loginRediresct() {
                window.location.href = 'https://merciq.app/staff/signin'
            },
        },
        beforeMount() {
            if(localStorage.getItem('lang') == undefined) {
                this.choiceLang = "EN"
                localStorage.setItem('lang', 'EN')
            } else {
                this.choiceLang = localStorage.getItem('lang')
            }
        }
    }
</script>