import { createRouter, createWebHistory } from 'vue-router'
import Main from '../pages/Main.vue'
import FAQ from '../pages/FAQ.vue'
import Cookie from '../pages/Cookie.vue'
import NotFound from '../pages/404.vue'
import ContactUs from '../pages/ContactUs.vue'
import Blog from '../pages/Blog.vue'

const routes = [
    {
        path: '/',
        name: 'main',
        component: Main,
    },
    {
        path: '/blog',
        name: 'blog & updates',
        component: Blog,
    },
    {
        path: '/contact',
        name: 'contact us',
        component: ContactUs,
    },
    {
        path: '/main/:lang',
        name: 'main lang',
        component: Main,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ,
    },
    {
        path: '/cookie',
        name: 'cookie',
        component: Cookie,
    },
    { 
        path: '/404', 
        name: 'not found',
        component: NotFound 
      },
    { path: "/:catchAll(.*)", redirect: '/404' },  
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
  
})
  
  export default router