import Vue from 'vue'
import App from './App.vue'
import './assets/style.css';
import router from './router/index.js'
import Cookie from './components/Cookie.vue'
import { createApp } from 'vue'
import  VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';

//Vue.config.productionTip = falsenpm 
const app = createApp(App)
app
.component('cookie', Cookie)
.use(VueTelInput)
.use(router)
.mount('#app');
// new Vue({
//   render: h => h(App),
// }).$mount('#app')
